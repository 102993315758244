import React from "react"

import PropTypes from "prop-types"

import "./alert.scss"

export const Alert = ({ title, description, button }) => {
  const props =
    typeof description === "string"
      ? { dangerouslySetInnerHTML: { __html: description } }
      : { children: description }

  return (
    <div className="alert alert-warning p-3 p-sm-4" role="alert">
      <h2 className="alert-heading mb-2 h4 mb-sm-3">{title}</h2>
      <p className="mb-0" {...props} />
      {button && button.label && (
        <a
          className="btn btn-secondary mt-3 d-inline-block"
          href={button.href}
          aria-label={button.ariaLabel}
        >
          {button.label}
        </a>
      )}
    </div>
  )
}

Alert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  button: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    ariaLabel: PropTypes.string,
  }),
}
